<template>
    <div
        v-if="dialog"
        class="confirm-modal"
        @click.self="close()"
    >
        <div class="confirm-modal__dialog">
            <header class="confirm-modal__header">
                <h2>Report a Problem</h2>
                <span
                    class="confirm-modal__close"
                    @click="close()"
                ><CloseIcon /></span>
            </header>
            <section class="confirm-modal__content">
                <hint
                    v-if="auth"
                    :text="`<span class='capitalize'>${client_data.firstname}</span>, have you found a technical problem with our website? Please help us improve. Send it to our engineers now!`"
                />
                <hint
                    v-else
                    :text="`Have you found a technical problem with our website? Please help us improve. Send it to our engineers now!`"
                />
            </section>
            <section class="confirm-modal__content">
                <form-textarea
                    v-model="$v.problem.$model"
                    legend="Details"
                    type="text"
                    name="problem"
                    :field.sync="problem"
                    :errors="validationMessage($v.problem)"
                    :is-valid="$v.problem.$dirty && !$v.problem.$anyError"
                    @input.native="$v.problem.$touch();"
                    @blur.native="$v.problem.$touch();"
                />
                <form-input
                    v-if="!auth"
                    v-model.trim="firstname"
                    legend="Firstname"
                    :field.sync="firstname"
                    name="name"
                />
                <form-input
                    v-if="!auth"
                    v-model.trim="emailValue"
                    legend="Email"
                    :field.sync="emailValue"
                    name="email"
                    :is-valid="$v.email.$dirty && !$v.email.$anyError"
                    :errors="validationMessage($v.email)"
                    @input.native="$v.email.$touch()"
                    @blur.native="$v.email.$touch()"
                />
                <div
                    v-if="!auth"
                    class="confirm-modal__phone"
                >
                    <phone-number-select
                        v-model="country"
                        label="Code"
                        tabindex="0"
                    />
                    <form-masked-input
                        v-model="phone_number"
                        :field.sync="phone_number"
                        class="confirm-modal__phone-number"
                        legend="Phone Number"
                        mask="################"
                        bg_color="#ffffff"
                        color="#000000"
                        name="phone_number"
                    />
                </div>
            </section>
            <footer>
                <div class="confirm-modal__one-button-block">
                    <!--                    <button class="confirm-modal__button confirm-modal__button&#45;&#45;gray" @click="close()">cancel</button>-->
                    <button
                        class="confirm-modal__one-button confirm-modal__one-button--main"
                        outlined
                        @click="prevalidate()"
                    >
                        ok
                    </button>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
// validation
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import CloseIcon from 'mdi-vue/Close.vue';
import { mapState } from 'vuex'
import { debounce } from 'lodash'
import formValidationMixin from '@/mixins/form-validation-mixin'
import { validationRules } from '@/validation/problem/rules-report-a-problem'
import { formMessages } from '@/validation/problem/messages-report-a-problem'

// components
import PhoneNumberSelect from '@/components/common/form-elements/phone/components/PhoneNumberSelect'

// vuex
import { mapPhoneFields } from '@/store/modules/phone'

import Api from '@/helpers/api/index.js'
import { eventBus } from '@/helpers/event-bus/'

export default {
    name: 'ReportModal',
    components: {
        PhoneNumberSelect,
        CloseIcon
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    validations: {
        ...validationRules
    },
    data() {
        return {
            comment: '',
            loadingSubmit: false,
            dialog: false,
            email: '',
            problem: '',
            emailValue: '',
            firstname: ''
        }
    },
    computed: {
        ...mapState('client', [
            'client_data',
            'auth'
        ]),
        ...mapPhoneFields([
            'form_data.country',
            'form_data.phone_number'
        ]),
        requestBody() {
            const requestBody = {
                email: this.email,
                country: this.country.text || '',
                phone_number: `${this.country.value}${this.country.phone_number || this.phone_number}` || '',
                details: this.problem,
                name: this.firstname
            }

            return requestBody
        }
    },
    watch: {
        emailValue: debounce(function (value) {
            this.email = value.trim()
        }, 500),
        dialog(val) {
            if (val && this.auth) {
                this.email = this.client_data.email
                this.firstname = this.client_data.firstname
                this.country = {
                    text: this.client_data.country,
                    value: this.client_data.phone_code,
                    phone_number: this.client_data.phone
                }
            }
        }
    },
    methods: {
        open(options) {
            this.dialog = true
            this.title = options.title
            this.description = options.description
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        },
        close() {
            this.resolve(false)
            this.dialog = false
        },
        clearPhone() {
            this.phone_number = ''
        },
        prevalidate() {
            this.validate()
        },
        validationMessage: validationMessage(formMessages),
        async submit() {
            const vm = this
            vm.loadingSubmit = true
            Api.post('/api/open/order/report-problem', this.requestBody)
                .then(() => {
                    eventBus.$emit('showSnackBar', 'Your comment has been saved', 'success')
                    vm.dialog = false
                    vm.email = this.auth ? this.client_data.email : ''
                    vm.country = ''
                    vm.phone_number = ''
                    vm.problem = ''
                })
        }
    }
}
</script>

<style lang="scss">
    .confirm-modal {
        position: fixed;
        overflow: hidden;
        background-color: rgba(11, 19, 31, 0.45);
        backdrop-filter: blur(3px);
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100vh;
        overscroll-behavior: contain;
        z-index: 900;
        display: flex;
        justify-content: center;
        align-items: center;
        &__phone {
            align-items: center;
            display: flex;
            height: 70px;
            .form-select {
                width: 120px;
                min-width: 120px;
                max-width: 120px;
                margin-right: -1px !important;
                border: none !important;
                &__list {
                    width: 285px;
                }
            }
            .form-masked-input__fieldset {
                input {
                    border-radius: 0 $input-border-radius $input-border-radius 0!important;
                    margin-top: 1px;
                }
            }
        }
        &__delete {
            position: absolute;
            top: 13px;
            right: 12px;
            cursor: pointer;
        }
        &__dialog {
            max-width: 500px;
            width: 100%;
            background-color: #fff;
            -webkit-box-shadow: 0 10px 40px rgba(25, 50, 71, 0.2);
            box-shadow: 0 10px 40px rgba(25, 50, 71, 0.2);
            border-radius: 4px;
            //position: fixed;
            top: 90px;
            left: calc(50% - 250px);
            z-index: 999999999;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @media (max-width: 480px) {
                max-width: 100%;
                top: 60px;
                left: 0;
                right: 0;
            }
        }
        &__header {
            font-weight: 500;
            color: $font-color-main;
            font-size: 24px;
            border-bottom: 1px solid $border-light;
            padding: 20px;
            position: relative;
        }
        &__close {
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            color: $second-color;
            cursor: pointer;
        }
        &__content {
            padding: 30px 30px 0;
            color: black;
            font-size: 16px;
            line-height: 1.3;
        }
        &__two-button-block {
            display: flex;
            justify-content: space-between;
            padding: 20px;
        }
        &__one-button-block {
            display: flex;
            justify-content: center;
            padding: 30px;
        }
        &__two-button {
            padding: 20px 0;
            width: calc(50% - 10px);
            text-transform: uppercase;
            font-size: 16px;
            font-weight: bold;
            border: none;
            border-radius: $btn-base__border-radius;
            cursor: pointer;
            &--gray {
                background-color: #f5f5f5;
                &:hover {
                    background-color: rgba(145, 149, 154, 0.2);
                    transition: 0.5s;
                }
            }
            &--main {
                background: $btn-main-background;
                box-shadow: none;
                color: $btn-main-color;
                &:hover {
                    background: $btn-main-background-hover;
                    color: $btn-main-color-hover;
                    transition: 0.5s;
                }
            }
        }
        &__one-button {
            padding: 20px 0;
            width: 100%;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: bold;
            border: none;
            border-radius: $btn-base__border-radius;
            cursor: pointer;
            outline: none;
            &--main {
                background: $btn-main-background;
                box-shadow: none;
                color: $btn-main-color;
                &:hover {
                    background: $btn-main-background-hover;
                    color: $btn-main-color-hover;
                    transition: 0.5s;
                }
            }
        }
        & .form-input__fieldset {
            margin: 30px 0;
        }
        & .form-masked-input__input {
            color: black;
        }
    }
    .capitalize{
        text-transform: capitalize;
    }
</style>
