import '~/index.scss'
import Vue from 'vue'
import VueMeta from 'vue-meta'
// import * as Sentry from '@sentry/vue';
// import { Integrations } from '@sentry/tracing';
import Api from '@/helpers/api/index.js'
import vClickOutside from 'v-click-outside'
import VueTelInput from 'vue-tel-input';
import Socket from '@/plugins/socket'
import createGtm from 'vue-gtm';

import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
// custom plugins
import FormElements from './plugins/form_elements.js'
import Elements from './plugins/elements'
import Icons from './plugins/icons.js'
import i18n from './i18n'

Vue.use(FormElements)
Vue.use(Elements)
Vue.use(vClickOutside)
Vue.use(Icons)
Vue.use(VueMeta)
Vue.use(Socket)
Vue.use(VueTelInput)

Vue.use(createGtm, {
    id: process.env.VUE_APP_GTM_ID,
    queryParams: {
        gtm_auth: process.env.VUE_APP_GTM_AUTH,
        gtm_preview: process.env.VUE_APP_GTM_PREVIEW,
        gtm_cookies_win: process.env.VUE_APP_GTM_COOKIES
    }
});

// if (process.env.NODE_ENV === 'production') {
//     Sentry.init({
//         Vue,
//         dsn: process.env.VUE_APP_SENTRY_DSN,
//         integrations: [
//             new Integrations.BrowserTracing({
//                 routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//                 tracingOrigins: [process.env.VUE_APP_DOMAIN, /^\//]
//             })
//         ],
//         // Set tracesSampleRate to 1.0 to capture 100%
//         // of transactions for performance monitoring.
//         // We recommend adjusting this value in production
//         tracesSampleRate: 1.0
//     });
// }

new Vue({
    data: { loading: true },
    router,
    store,
    Api,
    i18n,
    render: (h) => h(App)
}).$mount('#app')
