import Vue from 'vue'
import Router from 'vue-router'

import { debounce } from 'lodash'
import { scroller } from 'vue-scrollto/src/scrollTo'
import { routes } from '@/router/routesAll.js'

import Auth from '@/helpers/auth/'

import store from '@/store'

Vue.use(Router)

const router = new Router({
    routes,
    mode: 'history',
    scrollBehavior: (to, from, savedPosition) => new Promise((resolve) => {
        if (to.name === from.name) return false
        // if need scroll to id
        if (to.hash) {
            setTimeout(() => {
                const element = document.getElementById(to.hash.replace(/#/, ''))
                if (element && element.scrollIntoView) {
                    const ScrollTo = scroller();
                    ScrollTo(element, {
                        duration: 600,
                        offset: -60
                    });
                    element.scrollIntoView({ block: 'start', behavior: 'smooth' })
                }
            }, 0)
        }
        if (from.name === 'orders' && to.name.includes('order_')) {
            return resolve({ x: 0, y: 0 })
        }
        if (savedPosition || to.meta.scrollToTop === false) {
            return router.app.$root.$once('triggerScroll', () => {
                console.log('triggerScroll');
                return debounce(() => resolve(savedPosition), 0)()
            });
        }
        return resolve({ x: 0, y: 0 })
    })
});

router.beforeEach(async (to, from, next) => {
    // redirect new clien with ZERO PAID orders to welcome route
    if (to.name === 'base') {
        if (store.getters['client/getPurchases'] === 0 || store.getters['client/getPurchases'] === undefined) {
            next({ name: 'welcome' })
            return
        }
        next({ name: 'orders' })
        return
    }

    // redirect logged in user to account page, in case an irrelevant route is accessed
    // if (shouldRedirectToAccount(to.name)) {
    //     next({ name: 'orders' })
    //     return
    // }

    // check a need redirect to page onboarding;
    if (store.getters['client/getterRegisterSecondStep']) {
        // add a check to prevent infinite loop
        if (to.name !== 'onboarding') {
            next({ name: 'onboarding' })
            return
        }
    }

    // if (to.name === 'onboarding' && !store.getters['client/getterRegisterSecondStep']) {
    //     next({ name: 'orders' })
    //     return
    // }

    // all routes processing logic
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (Auth.isLogged()) {
            next()
        } else {
            Auth.logout()
            Auth.logoutVuex()
            localStorage.setItem('login_last_location', window.location.href)
            next({ name: 'login' })
        }
    } else {
        next();
    }
})

export default router
