<template>
    <div @click.stop>
        <Transition name="slide-fade">
            <div
                v-if="showMenu"
                class="profile-dropdown-mobile__list-wrapper"
            >
                <div class="profile-dropdown-mobile__list-topic-wrapper">
                    <span class="profile-dropdown-mobile__arrow--left" />
                    <span
                        class="profile-dropdown-mobile__list-topic text-color"
                        @click.prevent="closeMenu"
                    >
                        Menu
                    </span>
                </div>
                <div class="profile-dropdown-mobile__container">
                    <div class="profile-dropdown-mobile__client-info-wrapper">
                        <div style="display:flex; flex-direction: column">
                            <div class="profile-dropdown-mobile__client-info">
                                <avatar
                                    :size="30"
                                    :name="getInitial"
                                />
                                <div class="profile-dropdown-mobile__client-info-personality">
                                    <span class="profile-dropdown-mobile__client-info-personality-name text-color">{{ getterMainClientInfo.firstname }} {{ getterMainClientInfo.lastname }}</span>
                                </div>
                            </div>
                            <div style="display:flex; align-items: baseline; justify-content: space-between;">
                                <div>
                                    <div class="profile-dropdown-mobile__client-info-personality-title text-color">
                                        <span style="display:flex; gap: 5px;">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 512 512"
                                                width="15px"
                                                fill="#fff"
                                            >
                                                <path d="M452 78.668h-18.668V60c0-33.086-26.914-60-60-60h-314C26.617 0 0 26.617 0 59.332V449c0 34.738 28.262 63 63 63h389c33.086 0 60-26.914 60-60V138.668c0-33.086-26.914-60-60-60zm20 255.664h-78.332c-21.508 0-39-17.492-39-39 0-21.504 17.492-39 39-39H472zM59.332 40h314c11.027 0 20 8.973 20 20v18.668h-334C48.672 78.668 40 69.992 40 59.332 40 48.672 48.672 40 59.332 40zM452 472H63c-12.684 0-23-10.316-23-23V115.434a59.112 59.112 0 0 0 19.332 3.23H452c11.027 0 20 8.973 20 20v77.668h-78.332c-43.563 0-79 35.441-79 79 0 43.563 35.437 79 79 79H472V452c0 11.027-8.973 20-20 20zm0 0" />
                                            </svg>
                                            <b style="font-size: 14px;"> {{ getterMainClientInfo.account_balance | money }}</b>
                                        </span>
                                    </div>
                                    <span
                                        class="loadfunds"
                                        @click="showTopUp=true"
                                    >
                                        Load funds
                                    </span>
                                    <TopUpModal
                                        v-if="showTopUp"
                                        @closeTopUp="showTopUp = false"
                                    />
                                </div>
                                <div>
                                    <span style="display:flex; gap: 5px; border-left: 1px solid #ccc; padding-left: 5px">
                                        <svg
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18px"
                                            fill="#fff"
                                        >
                                            <path
                                                d="M21.41 11.58L12.41 2.58C12.05 2.22 11.55 2 11 2H4C2.9 2 2 2.9 2 4V11C2 11.55 2.22 12.05 2.59 12.42L11.59 21.42C11.95 21.78 12.45 22 13 22C13.55 22 14.05 21.78 14.41 21.41L21.41 14.41C21.78 14.05 22 13.55 22 13C22 12.45 21.77 11.94 21.41 11.58ZM13 20.01L4 11V4H11V3.99L20 12.99L13 20.01Z"
                                            />
                                            <path
                                                d="M6.5 8C7.32843 8 8 7.32843 8 6.5C8 5.67157 7.32843 5 6.5 5C5.67157 5 5 5.67157 5 6.5C5 7.32843 5.67157 8 6.5 8Z"
                                            />
                                            <path
                                                d="M8.8999 12.55C8.8999 13.12 9.1299 13.62 9.4999 14L12.9999 17.5L16.4999 14C16.8544 13.6096 17.045 13.0977 17.0323 12.5705C17.0195 12.0433 16.8044 11.5412 16.4316 11.1683C16.0587 10.7955 15.5566 10.5804 15.0294 10.5676C14.5022 10.5549 13.9903 10.7455 13.5999 11.1L12.9999 11.7L12.3999 11.11C12.0299 10.73 11.5099 10.5 10.9499 10.5C9.8199 10.5 8.8999 11.42 8.8999 12.55Z"
                                            />
                                        </svg>
                                        <b
                                            class="text-color"
                                            style="font-size: 14px"
                                        >
                                            {{ getterMainClientInfo.loaylty_discount | percent }}
                                        </b>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div
                            @click.prevent="goToPage('profile')"
                        >
                            <!-- <div class="mobile-menu__item">
                                <div
                                    class="mobile-menu__icon"
                                    :class="`profile-dropdown-mobile__list-item-icon-profile`"
                                />
                            </div> -->
                            <a
                                href="#"
                                class="profile-dropdown-mobile__list-item signout"
                                @click.prevent="logout()"
                            >
                                <span
                                    class="profile-dropdown-mobile__list-item-icon profile-dropdown-mobile__list-item-icon-logout"
                                    style="background-color: #435A7D"
                                />
                                <span class="profile-dropdown-mobile__list-item-text">
                                    Sign Out
                                </span>
                            </a>
                        </div>
                    </div>
                    <nav class="profile-dropdown-mobile__clien-info-nav">
                        <ul>
                            <li
                                v-for="item in getMenuList"
                                :key="item.routeName"
                            >
                                <div
                                    class="profile-dropdown-mobile__list-item"
                                    @click.prevent="goToPage(item.routeName, item.page)"
                                >
                                    <span
                                        class="profile-dropdown-mobile__list-item-icon"
                                        :class="`profile-dropdown-mobile__list-item-icon-${item.routeName}`"
                                    />
                                    <span class="profile-dropdown-mobile__list-item-text text-color">
                                        {{ item.title }}
                                    </span>
                                </div>
                            </li>
                        </ul>
                        <!-- <a
                            href="#"
                            class="profile-dropdown-mobile__list-item"
                            @click.prevent="logout()"
                        >
                            <span class="profile-dropdown-mobile__list-item-icon profile-dropdown-mobile__list-item-icon-logout" />
                            <span class="profile-dropdown-mobile__list-item-text">
                                Sign Out
                            </span>
                        </a> -->
                    </nav>
                    <div
                        class="profile-dropdown-mobile__support-wrapper"
                        @click.prevent="goToPage('support_form')"
                    >
                        <div class="profile-dropdown-mobile__support">
                            <span class="profile-dropdown-mobile__support-icon" />
                            <div class="profile-dropdown-mobile__support-personality">
                                <span class="profile-dropdown-mobile__support-personality-title text-color">Have a problem?</span>
                                <span class="profile-dropdown-mobile__support-personality-name text-color">Contact Us</span>
                            </div>
                        </div>
                        <span class="profile-dropdown-mobile__arrow" />
                    </div>
                    <div
                        v-if="chatVisible"
                        class="profile-dropdown-mobile__support-wrapper"
                        @click="setShowMessenger(!getterShowMessenger)"
                    >
                        <div class="profile-dropdown-mobile__support">
                            <img
                                :src="`/img/operators/${operatorImage}.jpg`"
                                alt="support agent"
                                class="support-messenger__image"
                                style="width:40px;height:40px;border-radius: 50%"
                            >
                            <div class="profile-dropdown-mobile__support-personality">
                                <span class="profile-dropdown-mobile__support-personality-title text-color">Instant</span>
                                <span class="profile-dropdown-mobile__support-personality-name text-color">Live Support</span>
                            </div>
                        </div>
                        <span class="profile-dropdown-mobile__arrow" />
                    </div>
                    <div style="padding: 10px">
                        <order-cart-button
                            @goToCheckout="$emit('close')"
                        />
                    </div>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script>
import { mapGetters, createNamespacedHelpers } from 'vuex'
import Avatar from '@/components/common/Avatar.vue'
import filtersMixin from '@/mixins/filters-mixin.js'
import OrderCartButton from '@/components/header/OrderCartButton.vue'
import TopUpModal from '@/components/account/TopUpModal';

import menuConstants from '@/constants/menuConstants.js'

import {
    ACTION_LOGOUT_CLIENT
} from '@/store/modules/client/action-types'

import {
    ACTION_CREATE_BATCH
} from '@/store/modules/checkout/action-types.js'

import {
    ACTION_SHOW_MESSENGER
} from '@/store/modules/messenger/action-types.js'

const {
    mapActions: mapClientActions
} = createNamespacedHelpers('client')

const {
    mapActions: mapCheckoutActions
} = createNamespacedHelpers('checkout')

const {
    mapActions: mapMessengerActions
} = createNamespacedHelpers('messenger')

export default {
    name: 'ControlPanelMenuMobile',
    components: {
        Avatar,
        OrderCartButton,
        TopUpModal
    },
    mixins: [filtersMixin],
    props: {
        showMenu: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            chatVisible: false,
            showTopUp: false
        }
    },
    computed: {
        // TODO: create function to check current window width and send prop to avatar component
        ...mapGetters('client', [
            'getterMainClientInfo',
            'getReferralId',
            'getterWriterOnline'
        ]),
        getInitial() {
            const { firstname, lastname } = this.getterMainClientInfo
            if (!!firstname && !!lastname) {
                return `${firstname[0]}${lastname[0]}`
            }
            return null
        },
        getClientName() {
            const { firstname, id } = this.getterMainClientInfo
            if (firstname) {
                return firstname.charAt(0).toUpperCase() + firstname.slice(1)
            }
            return `User ${id}`
        },

        ...mapGetters('checkout', [
            'getterCart'
        ]),
        getMenuList() {
            return menuConstants
        },
        ...mapGetters('messenger', [
            'getterShowMessenger'
        ]),
        operatorImage() {
            return Math.floor(Math.random() * 7) + 1
        }
    },
    async mounted() {
        this.chatVisible = Boolean(localStorage.getItem('chat-hidden'))
    },
    methods: {
        ...mapClientActions([
            ACTION_LOGOUT_CLIENT
        ]),
        ...mapCheckoutActions([
            ACTION_CREATE_BATCH
        ]),
        ...mapMessengerActions([
            ACTION_SHOW_MESSENGER
        ]),
        async logout() {
            try {
                await this[ACTION_LOGOUT_CLIENT]()
                this.$router.push({ name: 'login' })
            } catch (e) {
                console.error(e)
            }
        },
        closeMenu() {
            document.body.style.overflow = 'initial'
            this.$emit('close')
        },
        goToPage(route, page) {
            this.$router.push({ name: route, params: { page } })
            document.body.style.overflow = 'initial'
            this.$emit('close')
        },
        setShowMessenger(val) {
            this[ACTION_SHOW_MESSENGER](val)
        }
    }
}
</script>
<style lang="scss">

.profile-dropdown-mobile{
    &__burger{
        display: none;
        @media screen and (max-width: 576px) {
            display: block;
            background-image: url("~@/assets/img/svg/burger-icon.svg");
            background-repeat: no-repeat;
            background-size: 20px;
            width: 20px;
            height: 17px;
            padding: 0 10px;
            margin: 0 15px;
            cursor: pointer;
        }
    }
    &__list-wrapper{
        display: none;
        @media screen and (max-width: 576px) {
            display: block;
            position: fixed;
            // overflow: scroll; /*hidden;*/
            background-color: $white;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: 100vh;
            overscroll-behavior: contain;
            z-index: -1;
        }
    }
    &__list-topic {
        height: 22px;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: #111720;
        padding: 30px 30px 30px 50px;
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        width: 100%;
        justify-content: center;
        &-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #000;
            padding-right: 35px;
            background-color: #333333;

            @include media480max {
                padding-right: 20px;
            }
        }
    }
    &__container{
        //padding: 0 20px;
        background-color: #333333;
        height: 100vh;
    }
    &__container .cart-btn-animated{
        display: flex;
        @media screen and (max-width: 576px) {
            margin-left: 0;
            width: 100%;
        }
    }
    &__client-info {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        &-wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            //margin-bottom: 10px;
            //border-bottom: 1px solid rgba(74, 156, 225, 0.5);
            //border-radius: 4px;
        }
        &-personality{
            display: flex;
            flex-direction: column;
            margin-left: 15px;
            font-size: 14px;
            &-title{
                display: flex;
                align-items: center;
                gap: 10px;
                font-weight: 400;
                line-height: 20px;
            }
            &-name{
                font-weight: 700;
                line-height: 20px;
            }
        }
    }
    &__close{
        background-image: url("~@/assets/img/svg/cross.svg");
        background-repeat: no-repeat;
        background-size: 15px;
        width: 20px;
        height: 15px;
        padding: 0 10px;
        cursor: pointer;
    }
    &__list-item{
        display: flex;
        align-items: center;
        padding: 10px 10px;
        cursor: pointer;
        &:hover {
            background-color: #F2F6FF;
        }
    }
    &__list-item-icon{
        width: 25px;
        height: 25px;
        margin-right: 10px;
        position: relative;
        mask-size: auto;
        mask-repeat: no-repeat;
        mask-position: center;
        background: #fff;

        &-account {
            mask-image: url("~@/assets/img/svg/account-icons/user_account.svg");
        }

        &-orders {
            mask-image: url("~@/assets/img/svg/account-icons/clipboard.svg");
        }

        &-billing {
            mask-image: url("~@/assets/img/svg/account-icons/minus-square.svg");
        }

        &-credits {
            mask-image: url("~@/assets/img/svg/account-icons/dollar-sign.svg");
        }

        &-tickets {
            mask-image: url("~@/assets/img/svg/account-icons/phone.svg");
        }

        &-profile {
            mask-image: url("~@/assets/img/svg/account-icons/settings.svg");
        }

        &-friends {
            mask-image: url("~@/assets/img/svg/account-icons/users.svg");
        }

        &-logout {
            mask-image: url("~@/assets/img/svg/account-icons/log-out.svg");
        }
        &-support_form {
            mask-image: url("~@/assets/img/svg/phone-forwarded.svg");
        }
    }
    &__list-item-text{
        font-size: 14px;
        font-weight: 700;
        color: #435A7D;
        //transition: color 0.2s ease;
    }
    &__support-wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        //border-top: 1px solid rgba($main-color, 0.5);
        //border-radius: 4px;
        //margin: 10px 0;
        cursor: pointer;
    }
    &__support{
        display: flex;
        align-items: center;
    }
    &__support-icon{
        background: rgba($main-color, 0.1);
        border-radius: 50px;
        width: 40px;
        height: 40px;
        &::before {
            content: '';
            display: block;
            min-width: 40px;
            height: 40px;
            mask-image: url('~@/assets/img/svg/phone-forwarded.svg');
            mask-repeat: no-repeat;
            mask-size: 32px;
            mask-position: center;
            background: $main-color;
        }
    }
    &__support-personality{
        display: flex;
        flex-direction: column;
        margin-left: 15px;
        font-size: 14px;
    }
    &__support-personality-title{
        font-weight: 400;
        line-height: 20px;
    }
    &__support-personality-name{
        font-weight: 700;
        line-height: 20px;
    }
    &__arrow {
        mask-image: url("~@/assets/img/svg/arrow.svg");
        background-repeat: no-repeat;
        background-size: 15px;
        width: 15px;
        height: 15px;
        transform: rotateY(-180deg);
        background-color: #fff;
    }
    &__arrow--left {
        mask-image: url("~@/assets/img/svg/arrow.svg");
        background-repeat: no-repeat;
        background-size: 15px;
        width: 15px;
        height: 15px;
        background-color: #fff;
        margin: 0 10px;
        position: absolute;
    }
}

.profile-dropdown-mobile__clien-info-nav{
    background: rgba($main-color, 0.1);
    border-bottom: 1px solid rgba($main-color, 0.5);
    border-top: 1px solid rgba($main-color, 0.5);
}

.signout {
    background-color: #eaeaea;
    border-radius: 8px;
}

.loadfunds {
    font-size: 12px;
    display: flex;
    padding: 9px 16px;
    background: #059d21;
    font-weight: bold;
    border-radius: 16px;
    margin-top: 10px;
    color: #fff;
    text-align: center;
}

.text-color {
    color: #fff;
}

</style>
