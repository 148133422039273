const Welcome = () => import('@/views/Welcome.vue')
const OrderList = () => import('@/views/account/order/OrderList.vue')

export default [
    {
        path: '/',
        component: OrderList,
        name: 'base',
        meta: {
            keepAlive: true,
            requiresAuth: true,
            layout: 'AccountLayoutWithBreadcrumbs',
            breadcrumbs: {
                name: 'Projects',
                additionalClass: 'bc-horizontal'
            }
        }
    },
    {
        path: '/welcome',
        component: Welcome,
        name: 'welcome',
        meta: {
            keepAlive: true,
            requiresAuth: true,
            layout: 'AccountLayoutWithoutBreadcrumbs',
            breadcrumbs: {
                name: '',
                additionalClass: 'bc-horizontal'
            }
        }
    }
]
