<template>
    <div
        class="support-messenger"
    >
        <SupportMessengerHeader
            :type="headerType"
            :show-avatars="Boolean(listTickets.length)"
            @goToBack="goToList"
            @closeMessenger="closeMessenger()"
        />

        <div
            class="support-messenger_tabs"
        >
            <div
                class="support-messenger_tab support-tab"
                :class="{'support-messenger_tab--active' : state === 'list' || state === 'chat' || state === 'create'}"
                @click="state = 'list'"
            >
                <span>Support</span>
                <div
                    v-if="getterUnreadCounters && getterUnreadCounters.open_tickets_count"
                    class="bubble--unread"
                >
                    {{ getterUnreadCounters.open_tickets_count }}
                </div>
            </div>
            <div
                v-if="getterIsAuth"
                class="support-messenger_tab writers-tab"
                :class="{'support-messenger_tab--active' : state === 'writers' || state === 'writer-chat'}"
                @click="state = 'writers'"
            >
                <span>Writers</span>
                <div
                    v-if="getterUnreadCounters && getterUnreadCounters.chats_count"
                    class="bubble--unread"
                >
                    {{ getterUnreadCounters.chats_count }}
                </div>
            </div>
            <div
                class="support-messenger_tab faq-tab"
                :class="{'support-messenger_tab--active' : state === 'faq'}"
                @click="state = 'faq'"
            >
                <span>FAQ</span>
            </div>
        </div>

        <div
            v-if="!isLoading"
            class="support-messenger_body"
            :class="{
                [`support-messenger_body--header-${headerType}`]: true,
                'support-messenger_body--scroll': (state !== 'chat' || state !== 'client-chat'),
                'support-messenger_body--scroll-visible': listTickets.length === 0 && state !== 'create',
                'support-messenger_body--chat': (state === 'chat' || state === 'writer-chat'),
                'support-messenger_body--empty-wrapper': state === 'list' && !listTickets.length
            }"
        >
            <transition
                mode="out-in"
                name="bounce"
            >
                <writers
                    v-if="state === 'writers' && getterIsAuth"
                    :list="listWritersChat"
                    @goToChat="goToChat($event, 'writer-chat')"
                />
            </transition>
            <transition
                mode="out-in"
                name="bounce"
            >
                <faq
                    v-if="state === 'faq'"
                    @goToCreate="goToCreate"
                />
            </transition>
            <transition
                mode="out-in"
                name="bounce"
            >
                <SupportMessengerList
                    v-if="state === 'list' && listTickets.length"
                    :list="listTickets"
                    @goToChat="goToChat($event, 'support-chat')"
                />
            </transition>
            <transition
                mode="out-in"
                name="bounce"
            >
                <SupportMessengerCreate
                    v-if="state === 'create'"
                    :ticket-creating="ticketCreating"
                    @createTicket="createTicket"
                />
            </transition>
            <transition
                mode="out-in"
                name="bounce"
            >
                <SupportMessengerEmpty
                    v-if="state === 'list' && !listTickets.length"
                    :class="{ 'with-tabs':getterIsAuth }"
                    @goToCreate="goToCreate"
                />
            </transition>
            <transition
                mode="out-in"
                name="bounce"
            >
                <SupportMessengerChat
                    v-if="state === 'chat'"
                    :messages="messages"
                    :ticketid="currentTicket"
                    @readMessages="readMessages"
                />
            </transition>
            <transition
                mode="out-in"
                name="bounce"
            >
                <WritersMessengerChat
                    v-if="state === 'writer-chat'"
                    :chat-id="currentWriterChat"
                    @readMessages="readMessagesWritersChat"
                />
            </transition>
        </div>

        <RadiusLoader v-if="isLoading" />

        <div
            v-if="state === 'list' && listTickets.length"
            class="support-messenger_footer"
        >
            <button
                class="btn-base btn-main"
                @click="state = 'create'"
            >
                New conversation
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters, createNamespacedHelpers } from 'vuex'
import Api from '@/helpers/api/index.js'
import { eventBus } from '@/helpers/event-bus/'
import {
    createTicket, getGuestTicket, setGuestTicket, getGuestTicketFromStorage, removeGuestTicketFromStorage
} from '@/services/support'
import {
    getWritersChatsList
} from '@/services/writers-chat'
import SupportMessengerHeader from '@/components/support-messenger/SupportMessengerHeader'
import SupportMessengerList from '@/components/support-messenger/SupportMessengerList'
import SupportMessengerCreate from '@/components/support-messenger/SupportMessengerCreate'
import SupportMessengerEmpty from '@/components/support-messenger/SupportMessengerEmpty'
import SupportMessengerChat from '@/components/support-messenger/SupportMessengerChat'
import Writers from '@/components/support-messenger/Writers'
import Faq from '@/components/support-messenger/Faq'
import { mapSupportFields } from '@/store/modules/support';
import WritersMessengerChat from '@/components/support-messenger/WritersMessengerChat.vue'
import RadiusLoader from '@/components/common/RadiusLoader.vue'
import {
    ACTION_GET_UNREAD_COUNTERS
} from '@/store/modules/client/action-types.js'

const {
    mapActions: mapClientActions
} = createNamespacedHelpers('client')

const updateInterval = 6 * 10 * 1000
export default {
    components: {
        SupportMessengerHeader,
        SupportMessengerList,
        SupportMessengerCreate,
        SupportMessengerEmpty,
        SupportMessengerChat,
        Writers,
        Faq,
        WritersMessengerChat,
        RadiusLoader
    },
    data() {
        return {
            isLoading: true,
            ticketCreating: false,
            listTickets: [],
            intervalId: null,
            listWritersChat: [],
            currentWriterChat: ''
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterIsAuth',
            'getterUnreadCounters'
        ]),
        ...mapSupportFields([
            'state',
            'currentTicket'
        ]),
        headerType() {
            return this.state === 'create' || this.state === 'chat' || this.state === 'writer-chat' ? 'small' : 'normal'
        },
        messages() {
            if (this.state === 'chat') {
                const ticket = this.listTickets.find((item) => item.ticketid === this.currentTicket)
                return ticket.messages
            }
            return []
        },
        getUnreadCounter() {
            return this.listTickets.reduce((acc, curr) => {
                if (curr.counters?.unread_messages_count) {
                    acc += curr.counters.unread_messages_count
                }
                return acc
            }, 0);
        },
        tabsState() {
            return (this.state === 'list' && this.listTickets.length) || this.state === 'writers' || this.state === 'faq'
        }
    },
    watch: {
        async getterIsAuth() {
            clearTimeout(this.intervalId)
            this.state = 'list'
            this.currentTicket = ''
            this.listTickets = []
            await this.getTickets()
            this.intervalId = setTimeout(async () => {
                await this.liveUpdate()
            }, updateInterval);
        },
        async state(value) {
            switch (value) {
            case 'writers':
                await this.getWritersChats()
                break
            case 'list':
                await this.getTickets()
                break
            default:
            }
        }
    },
    async created() {
        if (this.getterIsAuth) {
            this[ACTION_GET_UNREAD_COUNTERS]()
        }
        this.state = 'list'
        await this.getTickets()
        this.intervalId = setTimeout(async () => {
            await this.liveUpdate()
        }, updateInterval);
    },
    mounted() {
        eventBus.$on('openChat', async (id, type) => {
            await this.goToChat(id, type)
        });
    },
    methods: {
        ...mapClientActions([
            ACTION_GET_UNREAD_COUNTERS
        ]),
        async goToList() {
            if (this.state === 'writer-chat') {
                this.state = 'writers'
                this.currentWriterChat = ''
                await this.getWritersChats()
                return
            }
            this.state = 'list'
            this.currentTicket = ''
            await this.getTickets()
        },
        goToCreate() {
            this.state = 'create'
            this.currentTicket = ''
        },
        goToChat(id, type) {
            console.log(type);
            switch (type) {
            case 'support-chat':
                this.state = 'chat'
                this.currentTicket = id
                break
            case 'writer-chat':
                this.state = 'writer-chat'
                this.currentWriterChat = id
                break
            default:
                this.state = 'chat'
                this.currentTicket = id
            }
        },
        closeMessenger() {
            window.parent.postMessage('closeChat', '*');
            this.$emit('closeMessenger')
        },
        async getWritersChats() {
            if (this.getterIsAuth) {
                try {
                    this.isLoading = true
                    this.listWritersChat = await getWritersChatsList()
                } catch (error) {
                    eventBus.$emit('showSnackBar', error, 'error');
                } finally {
                    this.isLoading = false
                }
            }
        },
        async getTickets() {
            this.isLoading = true
            if (!this.getterIsAuth) {
                const requests = []
                getGuestTicketFromStorage().forEach((ticket) => {
                    requests.push(getGuestTicket({ ticketid: ticket.ticketid, hash: ticket.hash }))
                });
                const removeIndexes = []
                await Promise.allSettled(requests)
                    .then((results) => results.forEach((result, index) => {
                        if (result.status === 'fulfilled') {
                            const findIn = this.listTickets.findIndex((item) => item.ticketid === result.value.ticketid)
                            if (findIn !== -1) {
                                this.listTickets.splice(findIn, 1, result.value)
                            } else {
                                this.listTickets.push(result.value)
                            }
                        } else {
                            removeIndexes.push(index)
                        }
                    }))
                    .finally(() => {
                        this.isLoading = false
                    })
                removeGuestTicketFromStorage(removeIndexes)
            } else {
                try {
                    const { data } = await Api.get('/api/support/auth/list-by-preset', {
                        mode: 'list',
                        page: 1,
                        per_page: 10,
                        preset: 'Tickets_Open'
                    })
                    this.listTickets = data.data
                } catch (error) {
                    eventBus.$emit('showSnackBar', error, 'error');
                } finally {
                    this.isLoading = false
                }
            }
        },
        async liveUpdate() {
            if (this.getterIsAuth) return
            const requests = []
            getGuestTicketFromStorage().forEach((ticket) => {
                requests.push(getGuestTicket({ ticketid: ticket.ticketid, hash: ticket.hash }))
            });
            const removeIndexes = []
            await Promise.allSettled(requests)
                .then((results) => results.forEach((result, index) => {
                    if (result.status === 'fulfilled') {
                        const findIn = this.listTickets.findIndex((item) => item.ticketid === result.value.ticketid)
                        if (findIn !== -1) {
                            this.listTickets.splice(findIn, 1, result.value)
                        } else {
                            this.listTickets.push(result.value)
                        }
                    } else {
                        removeIndexes.push(index)
                    }
                }))
            removeGuestTicketFromStorage(removeIndexes)
            this.intervalId = setTimeout(async () => {
                await this.liveUpdate()
            }, updateInterval);
        },
        async createTicket(payload) {
            try {
                this.ticketCreating = true
                const data = await createTicket(payload, this.getterIsAuth)
                this.currentTicket = data.ticketid
                if (!this.getterIsAuth) {
                    setGuestTicket(data)
                }
                clearTimeout(this.intervalId)
                await this.getTickets()
                this.state = 'chat'
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.ticketCreating = false
            }
        },
        readMessages(ticketid) {
            const findIn = this.listTickets.findIndex((item) => item.ticketid === ticketid)
            this.listTickets.splice(findIn, 1, {
                ...this.listTickets[findIn],
                counters: {
                    unread_messages_count: 0
                }
            })
        },
        readMessagesWritersChat(chatId) {
            const findIn = this.listWritersChat.findIndex((item) => item.id === chatId)
            this.listWritersChat.splice(findIn, 1, {
                ...this.listWritersChat[findIn],
                counters: {
                    unread_messages_count: 0
                }
            })
        }
    }
}
</script>

<style lang="scss">
    // @import "~@/assets/styles/common/components/_support-messenger";
</style>
