const OrderSelect = () => import(/* webpackChunkName: "order-form" */ '@/views/order/Select.vue')
const OrderStandard = () => import(/* webpackChunkName: "order-form" */ '@/views/order/Standard.vue')
const OrderExpress = () => import(/* webpackChunkName: "order-form" */ '@/views/order/Express.vue')

export default [
    {
        // this creates new order and puts it in the cart
        path: '/order/select',
        component: OrderSelect,
        name: 'order_select',
        meta: {
            requiresAuth: true,
            layout: 'EmptyLayout',
            breadcrumbs: {
                bgWrapper: true,
                name: 'Choose service',
                excerpt: `There are 2 ways to order. The Standard option is fully managed by our project managers, who will assign the best possible writer
                 or your preferred writer, if selected. The Express option is the marketplace model, where you pick the best writer yourself.`,
                additionalClass: 'bc-horizontal  bc-order-form  order-form-new'
            }
        }
    },
    {
        // this creates new order and puts it in the cart
        path: '/order/standard',
        component: OrderStandard,
        name: 'order_new',
        meta: {
            requiresAuth: true,
            layout: 'EmptyLayout',
            breadcrumbs: {
                bgWrapper: true,
                name: 'New Order',
                excerpt: '',
                additionalClass: 'bc-horizontal bc-order-form'
            }
        }
    },
    {
        // this edits existing order
        path: '/order/edit/:orderid',
        component: OrderStandard,
        name: 'order_edit',
        meta: {
            requiresAuth: true,
            layout: 'EmptyLayout',
            breadcrumbs: {
                bgWrapper: true,
                name: 'Edit Order',
                excerpt: '',
                additionalClass: 'bc-horizontal bc-order-form'
            }
        }
    },
    {
        // this creates new order and puts it in the cart
        path: '/order/express',
        component: OrderExpress,
        name: 'fast-track',
        meta: {
            layout: 'EmptyLayout',
            breadcrumbs: {
                bgWrapper: true,
                name: 'Express Order',
                excerpt: '',
                additionalClass: 'bc-horizontal bc-order-form'
            }
        }
    }
]
