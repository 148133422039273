<template>
    <div
        class="empty-layout"
        :class="{'empty-layout-login--page': $route.meta.showLoginHeader}"
    >
        <entering-form-header
            v-if="$route.meta.showLoginHeader"
            :show-btns="$route.name !== 'onboarding' && $route.name !== 'fake-reviews'"
        />
        <header-navigation
            v-if="isRouteNameAccountOrTickets"
        />
        <transition
            name="fade"
            mode="out-in"
            @after-leave="$root.$emit('triggerScroll')"
        >
            <keep-alive v-if="$route.meta.keepAlive">
                <router-view :key="$route.fullpath" />
            </keep-alive>
            <router-view
                v-else
                :key="$route.fullpath"
            />
        </transition>
        <main-footer
            v-if="isRouteNameAccountOrTickets"
        />
        <slot name="modals" />
        <global-modals />
        <notifier v-if="$socket.isConnected && $route.name !== 'checkout-processing'" />
        <support-messenger v-if="layout !== 'MessengerLayout' && $route.name !== 'checkout-processing'" />
        <!-- <update-notifications /> -->
        <!-- <modal-account-fill-notificatons /> -->
    </div>
</template>

<script>
import MainFooter from '@/components/Footer';
import EnteringFormHeader from '@/components/EnteringFormHeader';

import GlobalModals from '@/components/GlobalModals.vue'
import SupportMessenger from '@/components/support-messenger/Index.vue'
// import UpdateNotifications from '@/components/update-notifications/Main.vue'
import Notifier from '@/components/Notifier.vue'

import HeaderNavigation from '../components/HeaderNavigation.vue';

export default {
    name: 'LayoutUnauthorized',
    components: {
        EnteringFormHeader,
        HeaderNavigation,
        MainFooter,
        SupportMessenger,
        GlobalModals,
        Notifier
    },
    computed: {
        isRouteNameAccountOrTickets() {
            return this.$route.meta.showHeader
        }
    }
}
</script>

<style lang="scss">
    .empty-layout{
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        #page-footer{
            margin-top: auto;
        }
    }
    .empty-layout-login--page{
        height: inherit !important;
        display: flex;
        background: linear-gradient(129deg,rgba($main-color,.1),rgba($second-color,.8)),url("~@/assets/img/bg3.png") 50% 50% no-repeat;
        background-size: cover;
    }
</style>
