<template>
    <div
        class="support-messenger_chat"
    >
        <div
            class="support-messenger_chat_wrapper"
        >
            <RadiusLoader v-if="listLoading && !messages.length" />
            <template v-else>
                <message
                    v-for="(messageData, message_index) in messages"
                    :key="message_index"
                    :message="messageData"
                />
            </template>
        </div>

        <div class="support-messenger_chat_form">
            <p
                class="support-messenger_typing-message typing-wrapper"
            >
                {{ typingMessage }}
                <span
                    v-if="typingMessage"
                    class="dot-flashing"
                />
            </p>
            <form-textarea
                v-model="message"
                class="form-textarea__input-messages"
                style="margin-right: 10px"
                legend="Message"
                type="text"
                name="message"
                :disabled="isLoading"
                :field.sync="message"
                :errors="validationMessage($v.message)"
                @input.native="$v.message.$touch()"
                @blur.native="onBlur"
                @focus.native="onFocus"
                @textareaBlur="onBlur"
                @textareaFocus="onFocus"
                @keyup.native="keyListner($event)"
            />

            <custom-button
                class="btn-base btn-main chat-actions-btn"
                :loading="isLoading"
                @on-btn-click="sendMessage()"
            >
                <replyIcon v-if="!isLoading " />
            </custom-button>
        </div>
    </div>
</template>

<script>
// import { debounce } from 'lodash'
import { mapGetters } from 'vuex'
import { eventBus } from '@/helpers/event-bus/'
// Validation
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import { required } from 'vuelidate/lib/validators'
import { formMessages } from '@/validation/support/messages'
import {
    sendMessage, readChat, getWriterChatMessages
} from '@/services/writers-chat'
// Components
import Message from '@/components/common/chat/Message.vue'
import replyIcon from '@/components/icons/reply.vue'
import RadiusLoader from '@/components/common/RadiusLoader.vue'

export default {
    name: 'WritersMessengerChat',
    components: {
        Message,
        replyIcon,
        RadiusLoader
    },
    mixins: [
        validationMixin
    ],
    props: {
        chatId: {
            type: [String, Number],
            required: true
        }
    },
    validations: {
        message: { required }
    },
    data() {
        return {
            message: '',
            messages: [],
            isLoading: false,
            listLoading: false,
            socket: null,
            typing: false,
            typingMessage: ''
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterMainClientInfo'
        ])
    },
    watch: {
        // message() {
        //     this.typing = true
        //     this.userTyping(this)
        // },
        typing(newVal) {
            this.$socket.emit('clientWriterChat:typing', {
                typing: newVal,
                chat_id: this.chatId
            })
        }
    },
    async created() {
        await Promise.all([this.getMessages(), this.markAsRead(), this.chatWSConnect()])
    },
    beforeDestroy() {
        this.$socket.emit('clientWriterChat:unsubscribe', this.chatId)
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        async getMessages() {
            try {
                this.listLoading = true
                const data = await getWriterChatMessages({
                    chat_id: this.chatId
                })
                this.messages = data

                this.$nextTick(() => {
                    this.scrollToEnd()
                })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.listLoading = false
            }
        },
        async sendMessage() {
            try {
                this.isLoading = true
                const data = await sendMessage({
                    chat_id: this.chatId,
                    details: this.message
                })
                this.messages.push(data)
                this.message = ''
                this.$v.$reset()
                this.$nextTick(() => {
                    this.scrollToEnd()
                })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.isLoading = false
            }
        },
        async markAsRead() {
            await readChat({
                chat_id: this.chatId
            })
            this.$emit('readMessages', this.chatId)
        },
        keyListner(e) {
            if ((e.ctrlKey && e.keyCode === 13) || (e.shiftKey && e.keyCode === 13)) {
                this.message += '\n'
                return
            }
            if (!e.ctrlKey && !e.shiftKey && e.keyCode === 13) {
                e.preventDefault();
                this.sendMessage()
            }
        },
        scrollToEnd() {
            const elem = document.querySelector('.support-messenger_chat_wrapper')
            elem.scrollTo({ top: 99999999, behavior: 'smooth' })
        },
        // userTyping: debounce((self) => {
        //     self.typing = false
        // }, 1000),
        chatWSConnect() {
            console.log(this.chatId);
            this.$socket.emit('clientWriterChat:subscribe', { chat_id: this.chatId })
            this.$socket.on('clientWriterChat:newMessage', (data) => {
                // if (data.author.id === this.getterMainClientInfo.id) return
                if (data.author.type === 'Client') return
                this.messages = [...this.messages, data]
                this.$nextTick(() => {
                    this.scrollToEnd()
                })
            })
            this.$socket.on('clientWriterChat:typing', (data) => {
                if (data.role === 'Client' || data.role === 'Lead') return
                this.typingMessage = data.message
            })

            this.$socket.on('disconnect', () => {
                console.log('disconnect');
                this.chatWSConnect()
            });
        },
        onBlur() {
            this.typing = false
            this.$v.message.$touch()
        },
        onFocus() {
            this.typing = true
        }
    }
}
</script>
