// ORDER
const Projects = () => import('@/views/Projects.vue')
const OrderDetailsDetails = () => import('@/views/account/order/order-details/tabs/Details.vue')
const OrderDetailsFiles = () => import('@/views/account/order/order-details/tabs/Files.vue')
const OrderDetailsMessages = () => import('@/views/account/order/order-details/tabs/Messages.vue')
const OrderDetailsHelpCenter = () => import('@/views/account/order/order-details/tabs/HelpCenter.vue')
const OrderAdditionalFiles = () => import('@/views/account/order/order-details/tabs/AdditionalFiles.vue')
const OrderDetailsExpress = () => import('@/views/account/order/order-details/Express.vue')
const SuccessfulRateWriter = () => import('@/views/account/SuccessfulRateWriter.vue')

const OrderDetailsLoading = () => import('@/views/account/order/order-details/Index.vue')
export default [
    {
        path: '/projects',
        component: Projects,
        name: 'projects',
        meta: {
            keepAlive: true,
            requiresAuth: true,
            layout: 'AccountLayoutWithBreadcrumbs',
            breadcrumbs: {
                name: 'Projects',
                additionalClass: 'bc-horizontal'
            }
        }
    },
    {
        path: '/successful-rate',
        component: SuccessfulRateWriter,
        name: 'successful_rate',
        meta: {
            keepAlive: false,
            requiresAuth: true,
            layout: 'EmptyLayout',
            breadcrumbs: {
                bgWrapper: true,
                name: 'Thank you!',
                additionalClass: 'bc-horizontal  bc-order-form  order-form-new'
            }
        }
    },
    {
        path: '/project/express/:id',
        component: OrderDetailsExpress,
        name: 'order_details_express',
        meta: {
            keepAlive: true,
            requiresAuth: true,
            layout: 'AccountLayoutWithBreadcrumbs',
            breadcrumbs: {
                name: 'Express Order Details',
                additionalClass: 'bc-horizontal',
                parent_route_names: ['orders'],
                hasBack: true
            }
        }
    },
    {
        path: '/order/:id',
        component: OrderDetailsLoading,
        meta: {
            keepAlive: true,
            requiresAuth: true,
            layout: 'AccountLayoutWithBreadcrumbs',
            breadcrumbs: {
                name: 'Order Details',
                additionalClass: 'bc-horizontal bc-horizontal__details',
                parent_route_names: ['orders']
            }
        },
        children: [
            {
                path: '',
                name: 'order_details',
                component: OrderDetailsDetails,
                meta: {
                    scrollToTop: false,
                    keepAlive: true,
                    requiresAuth: true,
                    layout: 'AccountLayoutWithBreadcrumbs',
                    breadcrumbs: {
                        name: 'Order Details',
                        layout: 'AccountLayoutWithBreadcrumbs',
                        parent_route_names: ['orders'],
                        hasBack: true
                    }
                }
            },
            {
                path: 'files',
                name: 'order_standard_files',
                component: OrderDetailsFiles,
                meta: {
                    scrollToTop: false,
                    keepAlive: true,
                    requiresAuth: true,
                    layout: 'AccountLayoutWithBreadcrumbs',
                    breadcrumbs: {
                        name: 'Order Files',
                        layout: 'AccountLayoutWithBreadcrumbs',
                        parent_route_names: ['orders'],
                        hasBack: true
                    }
                }
            },
            {
                path: 'additional_files',
                name: 'order_standard_additional_files',
                component: OrderAdditionalFiles,
                meta: {
                    scrollToTop: false,
                    keepAlive: true,
                    requiresAuth: true,
                    layout: 'AccountLayoutWithBreadcrumbs',
                    breadcrumbs: {
                        name: 'Order Additional Files',
                        layout: 'AccountLayoutWithBreadcrumbs',
                        parent_route_names: ['orders'],
                        hasBack: true
                    }
                }
            },
            {
                path: 'messages',
                name: 'order_standard_messages',
                component: OrderDetailsMessages,
                meta: {
                    scrollToTop: false,
                    keepAlive: true,
                    requiresAuth: true,
                    layout: 'AccountLayoutWithBreadcrumbs',
                    breadcrumbs: {
                        name: 'Order Messages',
                        layout: 'AccountLayoutWithBreadcrumbs',
                        parent_route_names: ['orders'],
                        hasBack: true
                    }
                }
            },
            {
                path: 'support',
                name: 'order_standard_support',
                component: OrderDetailsHelpCenter,
                meta: {
                    scrollToTop: false,
                    keepAlive: true,
                    requiresAuth: true,
                    layout: 'AccountLayoutWithBreadcrumbs',
                    breadcrumbs: {
                        name: 'Order help center',
                        layout: 'AccountLayoutWithBreadcrumbs',
                        parent_route_names: ['orders'],
                        hasBack: true
                    }
                }
            }
        ]
    }
]
