<template>
    <div class="wrapper account-preferred-writers_wrapper">
        <div class="account-preferred-writers-sidebar">
            <div
                class="account-preferred-writers-sidebar_btn report"
                @click.stop="showPanel = !showPanel"
            >
                <span class="tool">
                    <span>Show {{ title }}:</span>
                </span>
                <component :is="icon" />
                <span class="bubble--unread bubble--unread-header">{{ writers.length }}</span>
            </div>
        </div>
        <div
            v-if="showPanel"
            class="modal-blur"
            style="z-index: 999;"
        />
        <Transition name="slide-fade">
            <div
                v-if="showPanel"
                v-click-outside="hideSidePanel"
                class="account-preferred-writers_panel"
            >
                <header class="account-preferred-writers_panel__header">
                    <span>
                        <span>{{ writers.length }}</span>
                        {{ title }}:
                    </span>
                    <div class="account-preferred-writers_panel__header_btns">
                        <button
                            class="btn-close"
                            @click="showPanel = false"
                        >
                            <CloseIcon />
                        </button>
                    </div>
                </header>
                <main class="account-preferred-writers_panel__body">
                    <span v-if="writers.length">
                        <transition-group name="delete">
                            <span
                                v-for="(writer, index) in writers"
                                :key="writer.id"
                                class="account-preferred-writers_panel__body-item"
                            >
                                <a
                                    :href="'/writers/profile/' + writer.id"
                                    class="account-preferred-writers_panel__body-writer-link"
                                >
                                    <span class="account-preferred-writers_panel__body-index">{{ index +1 }}</span>
                                    <avatar
                                        :avatar-fallback="writer.avatar_fallback"
                                        :avatar-url="writer.avatar_url"
                                        :nickname="writer.nickname"
                                        :firstname="writer.firstname"
                                        :lastname="writer.lastname"
                                    />
                                    <div class="account-preferred-writers_panel__body-writer-info">
                                        <div class="account-preferred-writers_panel__body-writer-info-name">{{ writer.name }}</div>
                                        <div class="account-preferred-writers_panel__body-writer-info-records">
                                            <span style="white-space: nowrap; margin-right: 5px">ID: <b>{{ writer.id }}</b></span>
                                            <span
                                                v-if="writer.statistics.orders_total"
                                                class="account-preferred-writers_panel__body-writer-subtitle-info"
                                            >
                                                <b>{{ writer.statistics.orders_total | number }}</b> assignments
                                            </span>
                                            <span
                                                v-if="writer.statistics.rank_all"
                                                class="account-preferred-writers_panel__body-writer-subtitle-info"
                                            >
                                                <b>{{ writer.statistics.rank_all | percentWriterRank }}</b> success rate
                                            </span>
                                            <span
                                                v-if="writer.statistics.rank_all"
                                                class="account-preferred-writers_panel__body-writer-subtitle-info"
                                            >
                                                based on <b>{{ writer.statistics.rank_all_count | number }}</b> reviews
                                            </span>
                                        </div>
                                    </div>
                                </a>
                                <img
                                    class="form-select-writers__btn-icon button-action-background"
                                    src="@/assets/img/svg/cross.svg"
                                    @click="deleteWriter(writer.id)"
                                >
                            </span>
                        </transition-group>
                    </span>
                    <div
                        v-else
                        class="account-preferred-writers_panel__body-no-writer"
                    >
                        No writer was found
                    </div>
                </main>
            </div>
        </Transition>
    </div>
</template>

<script>
import CloseIcon from 'mdi-vue/Close.vue';
import ClickOutside from 'vue-click-outside'
import Writers from '@/components/icons/Writers'
import WritersBlocked from '@/components/icons/Writers-blocked'
import Avatar from '@/components/common/Avatar.vue'
import Helper from '@/helpers/functions'
import filtersMixin from '@/mixins/filters-mixin'

export default {
    components: {
        CloseIcon,
        Preferred: Writers,
        Blocked: WritersBlocked,
        Avatar
    },
    directives: {
        ClickOutside
    },
    mixins: [
        filtersMixin
    ],
    props: {
        icon: {
            type: String
        },
        title: {
            type: String
        },
        writers: {
            type: Array
        }
    },
    data: () => ({
        showPanel: false
    }),
    watch: {
        showPanel(val) {
            Helper.lockBody(val)
        }
    },
    methods: {
        hideSidePanel(e) {
            if (e.target.className !== 'form-select__list-item') {
                this.showPanel = false
            }
        },
        deleteWriter(id) {
            this.$emit('deleteWriter', id)
        }
    }
}
</script>

<style lang="scss">
    .account-preferred-writers_wrapper{
        margin-bottom: 30px;
        position: relative;
    }
    .account-preferred-writers-sidebar{
        position: absolute;
        top: 45px;
        transform: translateY(-100%);
        right: -87px;
        display: flex;
        align-items: center;
        &_btn{
            margin-left: 20px;
            display: flex;
            align-items: center;
            cursor: pointer;
            position: relative;
            svg {
                width: 36px;
                height: 36px;
            }
        }
    }

    .account-preferred-writers_panel{
        background: #fff;
        position: fixed;
        z-index: 2147483002;
        top: 0;
        bottom: 0;
        right: 0;
        min-width: 600px;
        padding: 0 20px;
        box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
        @media (max-width: 768px) {
            min-width: 375px;
        }
        &__header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 25px 0 25px 10px;
            border-bottom: 1px solid rgba(0,0,0,.12);
            span {
                font-size: 22px;
                font-weight: bold;
                @media all and (max-width: 992px) {
                    font-size: 18px;
                }
            }
            span span {
                margin-right: 10px;
            }
            .btn-close{
                background: transparent;
                border: none;
                margin-left: 30px;
                cursor: pointer;
            }
        }
        &__body{
            position: relative;
            overflow: scroll;
            overflow-x: hidden;
            height: calc(100% - 80px);

        }
        &__body-item{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px 25px 15px 10px;
            border-bottom: 1px solid #dce2ea;
            margin-right: 10px;
            &:hover {
                background-color: rgb(230, 230, 230);
            }
        }
        &__body-writer-link{
            display: flex;
            align-items: center;
            .avatar-img{
                border: 1px solid;
                width: 40px;
                height: 40px;
                margin-right: 15px;
            }
        }
        &__body-index{
            font-size: 14px;
            color: #000;
            width: 30px;
        }
        &__body-writer-info{
            color: #7E8993;
            //color: #abb1bb;
        }
        &__body-writer-info div{
            //line-height: 1.3;
        }
        &__body-no-writer{
            margin: 0;
            position: absolute;
            top: 50%;
            left: 25%;
        }
        &__body-writer-info-name{
            color: #000000;
            font-weight: 400;
            margin-bottom: 4px;
            @media all and (max-width: 992px) {
                font-size: 14px;
            }
        }
        &__body-writer-info-records{
            flex-wrap: wrap;
            display: flex;
            align-items: center;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.9);
            span:nth-child(2) {
                margin-top: 5px;
                margin-bottom: 5px;
            }
        }
        &__body-writer-subtitle-info{
            padding: 5px;
            margin-right: 5px;
            border-radius: 10px;
            white-space: nowrap;
        }
    }
</style>
