export default [
    // {
    //     title: 'Notifications',
    //     route_name: 'notifications',
    //     icon: 'bell',
    //     path: '/notifications'
    // },
    {
        title: 'Projects',
        route_name: 'projects',
        icon: 'orders'
    },
    {
        title: 'Writers',
        route_name: 'writers-account',
        icon: 'pen',
        path: '/writers-account'
    },
    {
        title: 'Help Center',
        route_name: 'tickets',
        params: { page: '1' },
        icon: 'tickets',
        path: '/help-center'
    },
    {
        title: 'Billing',
        route_name: 'billing',
        params: { page: '1' },
        icon: 'billing',
        path: '/billing'
    },
    {
        title: 'Credits',
        route_name: 'credits',
        params: { page: '1' },
        icon: 'billing',
        path: '/credits'
    },
    {
        title: 'Affiliate Program',
        route_name: 'affiliate',
        params: { page: '1' },
        icon: 'credits',
        path: '/affiliate'
    }
    // {
    //     title: 'My Friends',
    //     route_name: 'friends',
    //     params: { page: '1' },
    //     icon: 'friends',
    //     path: '/referrals'
    // }
]
