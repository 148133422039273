<template
    lang="html"
    name="Header"
>
    <header
        id="header"
        class="mobile-menu"
    >
        <div class="mobile-menu__wrapper">
            <div
                class="mobile-menu__item"
                :class="$route.path === '/' ? 'mobile-menu__item--active' : ''"
                @click="goPage('orders')"
            >
                <div
                    class="mobile-menu__icon"
                    :class="`mobile-menu__icon-orders`"
                />
                <p>Projects</p>
            </div>
            <!--            <router-link :to="{ name: 'tickets' }">-->
            <!--                <div-->
            <!--                    class="mobile-menu__item"-->
            <!--                    :class="$route.path === '/help-center' ? 'mobile-menu__item&#45;&#45;active' : ''"-->
            <!--                >-->
            <!--                    <div-->
            <!--                        class="mobile-menu__icon"-->
            <!--                        :class="`mobile-menu__icon-tickets`"-->
            <!--                    />-->
            <!--                    <p>Help Center</p>-->
            <!--                </div>-->
            <!--            </router-link>-->
            <div
                class="mobile-menu__item"
                :class="$route.path === '/notifications' ? 'mobile-menu__item--active' : ''"
                @click="goPage('notifications')"
            >
                <div
                    class="mobile-menu__icon"
                    :class="`mobile-menu__icon-notifications`"
                />
                <p class="">
                    Notifications
                </p>
            </div>
            <div
                class="mobile-menu__item"
                :class="$route.path === '/writers-account' ? 'mobile-menu__item--active' : ''"
                @click="goPage('writers-account')"
            >
                <div
                    class="mobile-menu__icon"
                    :class="`mobile-menu__icon-writers`"
                />
                <p class="">
                    Writers
                </p>
            </div>
            <div @click.stop="isShowMenu">
                <div class="mobile-menu__item">
                    <div
                        class="mobile-menu__icon"
                        :class="`mobile-menu__icon-more`"
                    />
                    <p class="">
                        More
                    </p>
                </div>
                <control-panel-menu-mobile
                    :show-menu="showMenu"
                    @close="closeControlPanel"
                />
            </div>
        </div>
    </header>
</template>
<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
// components
import ControlPanelMenuMobile from '@/components/header/ControlPanelMenuMobile'

import {
    ACTION_CREATE_BATCH
} from '@/store/modules/checkout/action-types.js'

const {
    mapActions: mapCheckoutActions
} = createNamespacedHelpers('checkout')

export default {
    components: {
        ControlPanelMenuMobile
    },
    data() {
        return {
            showMenu: false
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterMainClientInfo'
        ]),
        ...mapGetters('checkout', [
            'getterCart'
        ]),
        getAppDomain() {
            return process.env.VUE_APP_DOMAIN;
        },
        getAppName() {
            return process.env.APP_NAME;
        },
        getAppUrl() {
            return process.env.APP_URL;
        }
    },
    methods: {
        ...mapCheckoutActions([
            ACTION_CREATE_BATCH
        ]),
        isShowMenu() {
            if (!this.showMenu) {
                this.showMenu = !this.showMenu
                document.body.style.overflow = 'hidden'
            } else {
                this.showMenu = !this.showMenu
                document.body.style.overflow = 'initial'
            }
        },
        closeControlPanel() {
            this.showMenu = false
        },
        goPage(name) {
            document.body.style.overflow = 'initial'
            this.showMenu = false
            this.$router.push({ name })
        }
    }
}
</script>
