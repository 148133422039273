const ReferralPage = () => import(/* webpackChunkName: "writers" */ '@/views/friends-main/FriendMain.vue')
const OfferPage = () => import(/* webpackChunkName: "writers" */ '@/views/offer/Main.vue')

export default [
    {
        path: '/referral/:referral_hash',
        component: ReferralPage,
        name: 'referral_page',
        meta: {
            requiresAuth: false,
            layout: 'EmptyLayout'
        }
    },
    {
        path: '/offer/:hash',
        component: OfferPage,
        name: 'offer_page',
        meta: {
            requiresAuth: false,
            layout: 'EmptyLayout'
        }
    }
]
